import React, { useState, useEffect } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import { InteractiveBrowserCredential } from '@azure/identity';
import JSZip, { version } from 'jszip';

const accountName = process.env.REACT_APP_ACCOUNTNAME; // Azure Storage account name
const clientId = process.env.REACT_APP_CLIRNTID_URL; // Azure AD application client ID
const tenantId = process.env.REACT_APP_TENANTID_URL;
// Function to format the date
const formatDate = (isoDate) => {
    const date = new Date(isoDate);

    // Extract date components
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedHours = hours.toString().padStart(2, '0');

    // Create and return the formatted date object
    return {
        date: `${day}-${month}-${year}`,
        time: `${formattedHours}:${minutes}`,
        ampm: ampm
    };
};

const AzureBlobStatusChecker = () => {
    const [accountStatus, setAccountStatus] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // checkAccountStatus();
    }, []);

    return (
        <div>
            <h1>Check Azure Blob Storage Account Status</h1>
            {loading ? <p>Loading...</p> : <p>{accountStatus}</p>}
        </div>
    );
};

export default AzureBlobStatusChecker;


export const checkAccountStatus = async () => {

    try {
        // Use InteractiveBrowserCredential for authentication with clientId
        const credential = new InteractiveBrowserCredential({
            clientId,
            tenantId
        });

        const blobServiceClient = new BlobServiceClient(
            `https://${accountName}.blob.core.windows.net`,
            credential,
        );

        // Attempt to list the containers
        const containerList = blobServiceClient.listContainers();

        const modifiedContainerData = [];
        for await (const container of containerList) {
            modifiedContainerData.push({
                name: container.name,
                // Assuming you might want to add more properties in the future
                isOpen: false,
                createdOn: container.properties.createdOn // Example property
            });
        }


        // Example: Remove a class from the DOM if needed
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        return modifiedContainerData; // Return the modified container data

    } catch (error) {
        return { error: true };
    }
};


// Create empty container
export const createContainer = async (containerName) => {

    try {
        // Step 1: Create the credentials object
        const credential = new InteractiveBrowserCredential({
            clientId,
            tenantId,
            scopes: ["https://storage.azure.com/.default"]  // Ensure the correct scope
        });

        // Step 2: Create the BlobServiceClient
        const blobServiceClient = new BlobServiceClient(
            `https://${accountName}.blob.core.windows.net`,
            credential,
        );

        // Step 3: Get the container client
        const containerClient = blobServiceClient.getContainerClient(containerName);

        // Step 4: Check if the container exists
        const exists = await containerClient.exists();
        if (exists) {
            console.log(`Container '${containerName}' already exists.`);
        } else {
            await blobServiceClient.createContainer(containerName);
        }
    } catch (error) {
        return { error: true };
    }
};

// Create folder using the path
export const createFolderByPathInContainer = async (containerName, fullPath) => {
    try {
        const credential = new InteractiveBrowserCredential({
            clientId,
            tenantId
        });

        const blobServiceClient = new BlobServiceClient(
            `https://${accountName}.blob.core.windows.net`,
            credential,
        );

        const containerClient = blobServiceClient.getContainerClient(containerName);

        // Create a folder by creating a blob with the full path
        const folderBlobClient = containerClient.getBlockBlobClient(`${fullPath}${fullPath.endsWith('/') ? '' : '/'}`);
        await folderBlobClient.upload("", 0); // Upload an empty blob
    } catch (error) {
        console.error('Error creating folder:', error);
        return { error: true };
    }
};


export const toHandleZipUploads = async (containerName, blobName, file) => {
    try {

        if (blobName.includes('BusinessReleaseDocs')) {
            result = await uploadZipContentsToContainer(containerName, blobName, file);
            if (result) {
                if (result.error) {
                    return { error: true }
                }
            }
            return;
        }
        let result;
        const metaDataResult = await getBlobMetadata(containerName, blobName + '/');
        if (metaDataResult) {
            if (metaDataResult.error) {
                return { error: true }
            }
        }
        if (metaDataResult != undefined || (metaDataResult != {})) {
            if (metaDataResult.status == 'waiting' || metaDataResult.status == 'approved' || metaDataResult.status == 'rejected') {
                // Get the current timestamp in the format YYYYMMDDHHMMSS
                let timestamp = new Date().toISOString().replace(/[^0-9]/g, '').slice(0, 14);
                // Split the blobName and insert 'Archive' before the last part
                let parts = blobName.split('/');
                parts.splice(parts.length - 1, 0, 'Archive');  // Add 'Archive' before 'CPCNEW'

                // Create the new folder path: Archive/CPCNEW/CPCNEWYYYYMMDDHHMMSS
                let actualFolderpathForBuild = parts.join('/') + '/' + parts[parts.length - 1] + timestamp + '.zip';
                result = await copyBlobsBetweenFolders(containerName, blobName + '/', actualFolderpathForBuild + "/");
                if (result) {
                    if (result.error) {
                        return { error: true }
                    }
                }
                result = await deleteBlobsFromFolder(containerName, blobName + '/');
                if (result) {
                    if (result.error) {
                        return { error: true }
                    }
                }
                result = await createFolderByPathInContainer(containerName, blobName);
                if (result) {
                    if (result.error) {
                        return { error: true }
                    }
                }
                result = await uploadZipContentsToContainer(containerName, blobName, file);
                if (result) {
                    if (result.error) {
                        return { error: true }
                    }
                }
            } else {
                result = await uploadZipContentsToContainer(containerName, blobName, file);
                if (result) {
                    if (result.error) {
                        return { error: true }
                    }
                }
            }
        }
    } catch {
        return { error: true };
    }

}
// Get blob metadata
export const getBlobMetadata = async (containerName, blobName) => {
    try {

        // Step 1: Create the credentials object
        const credential = new InteractiveBrowserCredential({
            clientId,
            tenantId,
            scopes: ["https://storage.azure.com/.default"]  // Ensure the correct scope
        });

        // Step 2: Create the BlobServiceClient
        const blobServiceClient = new BlobServiceClient(
            `https://${accountName}.blob.core.windows.net`,
            credential
        );

        // Step 3: Get the container client
        const containerClient = blobServiceClient.getContainerClient(containerName);

        // Step 4: Get the blob client
        const blobClient = containerClient.getBlobClient(blobName);

        // Step 5: Get blob metadata
        const blobProperties = await blobClient.getProperties();

        // Return metadata
        return blobProperties.metadata;
    } catch (error) {
        return { error: true };
    }
};

// upoload the blob 
export const uploadZipContentsToContainer = async (containerName, fullPath, file) => {
    let errorResult;
    try {
        const newMetadata = {
            status: 'waiting',
            userName: 'value2',
            comment: '',
            version: containerName + '-' + fullPath.replaceAll("/", '_') + '.1'
        };
        const credential = new InteractiveBrowserCredential({
            clientId,
            tenantId
        });

        const blobServiceClient = new BlobServiceClient(
            `https://${accountName}.blob.core.windows.net`,
            credential,
        );

        const containerClient = blobServiceClient.getContainerClient(containerName);

        // Check if the file is a ZIP file
        if (file.name.endsWith('.zip')) {
            const zip = await JSZip.loadAsync(file);

            for (const fileName in zip.files) {
                const zipFile = zip.files[fileName];

                if (zipFile.dir) continue; // Skip directories

                const fileContent = await zipFile.async('uint8array');

                // Split the file path and remove the first part (root folder)
                const filePathParts = fileName.split('/');
                filePathParts.shift();  // Remove the first folder (root folder)
                const adjustedFileName = filePathParts.join('/');  // Rebuild the path without the root folder

                // Check for empty filenames
                if (!adjustedFileName) {
                    console.warn('Skipping unnamed file in ZIP');
                    continue;
                }

                // Use the adjusted file name for the blob path
                const blobPath = `${fullPath}/${adjustedFileName}`;
                console.log(`Uploading file: ${blobPath}`);
                const blobClient = containerClient.getBlockBlobClient(blobPath);

                await blobClient.upload(fileContent, fileContent.byteLength);

                // Verify size
                const properties = await blobClient.getProperties();
                console.log(`Uploaded "${adjustedFileName}" successfully. Size on server: ${properties.contentLength} bytes.`);
                errorResult = await updateBlobMetadata(containerName, fullPath + '/', newMetadata);
                if (errorResult && errorResult.error) {
                    console.error('Error updating metadata:', errorResult);
                    return { error: true };
                }
            }
        } else {
            // Handle single file upload
            const fileContent = await file.arrayBuffer(); // Read as ArrayBuffer
            const blobPath = `${fullPath}/${file.name}`;
            console.log(`Uploading file: ${blobPath}`);
            const blobClient = containerClient.getBlockBlobClient(blobPath);

            await blobClient.upload(fileContent, file.size);

            // Verify size
            const properties = await blobClient.getProperties();
            console.log(`Uploaded "${file.name}" successfully. Size on server: ${properties.contentLength} bytes.`);
            errorResult = await updateBlobMetadata(containerName, fullPath + '/', newMetadata);
            if (errorResult && errorResult.error) {
                console.error('Error updating metadata:', errorResult);
                return { error: true };
            }
        }
    } catch (error) {
        console.error('Error uploading to container:', error);
        return { error: true };
    }
};


function isFolderPath(name) {
    const isFile = /\.\w+$/.test(name); // Check for any file extension
    const hasVersionPattern = /\d+\.\d+/.test(name); // Check for version-like patterns
    return !isFile || hasVersionPattern; // Return true if it's not a file or has a version pattern
}



// get the liost for folder and files in the azure blob working fine 
export const listBlobsInContainer = async (containerName, folderPath) => {
    try {
        const credential = new InteractiveBrowserCredential({
            clientId,
            tenantId
        });

        const blobServiceClient = new BlobServiceClient(
            `https://${accountName}.blob.core.windows.net`,
            credential
        );

        const containerClient = blobServiceClient.getContainerClient(containerName);
        const blobList = [];
        let continuationToken = null;

        do {
            const response = await containerClient.listBlobsFlat({
                prefix: folderPath + '/',
                continuationToken
            });

            // Process blobs in the current batch
            for await (const blob of response) {
                const relativePath = blob.name.substring(folderPath.length).replace(/^\/+/, ''); // Remove leading slashes                
                const parts = relativePath.split('/').filter(part => part); // Split and filter out empty parts

                if (parts.length > 0) {
                    const name = parts[0]; // Get the first part after the folderPath
                    if (!name) continue; // Skip if name is empty

                    // Ensure the name matches what you're expecting based on your requirements
                    if (parts.length > 1 && parts[1].includes('/')) {
                        continue; // Skip if there's more than one part, indicating an incorrect structure
                    }

                    const lastChangedDate = formatDate(blob.properties.lastModified);
                    const isFolder = isFolderPath(name); // Determine if it's a folder

                    // Create the full path
                    const fullPath = `${folderPath}/${name}`;

                    // Check for duplicates before adding to the list
                    const existing = blobList.find(item => item.path === fullPath);
                    if (existing) continue;

                    // Create the object in the desired format
                    const formattedBlob = {
                        path: fullPath,
                        isFolder: isFolder,
                        isOpen: false,
                        BuildId: '',
                        buildNumber: '',
                        srcPath: '',
                        lastChangedDate
                    };
                    blobList.push(formattedBlob);
                }
            }


            continuationToken = response.continuationToken; // Update the continuation token

        } while (continuationToken); // Continue fetching until there are no more tokens

        // Print the final categorized blob list to the console
        return blobList; // Return the list of blobs
    } catch (error) {
        return { error: true };
    }
};


// Helper function to initialize BlobServiceClient
const initializeBlobServiceClient = () => {
    const credential = new InteractiveBrowserCredential({
        clientId,
        tenantId,
    });
    const blobServiceClient = new BlobServiceClient(
        `https://${accountName}.blob.core.windows.net`,
        credential
    );
    return blobServiceClient;
};

export const downloadBlob = async (containerName, folderPath) => {
    const fileName = folderPath.split('/').pop();
    try {
        const blobServiceClient = initializeBlobServiceClient();
        const containerClient = blobServiceClient.getContainerClient(containerName);

        const isFolder = folderPath.endsWith('/');
        console.log(isFolder, folderPath);

        if (isFolder) {
            return await downloadFolderAsZip(containerName, folderPath, blobServiceClient);
        } else {
            return await downloadSingleBlob(containerClient, folderPath);
        }
    } catch (error) {
        return { error: true };
    }
};


// Download a single blob
// Function to download a blob from Azure Blob Storage to a user-selected directory
const downloadSingleBlob = async (containerClient, blobName) => {
    const blobClient = containerClient.getBlobClient(blobName);
    const exists = await blobClient.exists();

    if (!exists) {
        return;
    }

    try {
        // Use the File System Access API to let the user choose a directory
        const directoryHandle = await window.showDirectoryPicker();
        const downloadBlockBlobResponse = await blobClient.download(0);
        const blobContent = await downloadBlockBlobResponse.blobBody;

        // Create a URL for the blob content
        const url = window.URL.createObjectURL(blobContent);



        const fileName = blobName.split('/').pop();  // Extract the file name from the blob path
        const fileHandle = await directoryHandle.getFileHandle(fileName, { create: true });

        // Open a writable stream to the selected directory and write the blob content to it
        const writableStream = await fileHandle.createWritable();
        const buffer = await blobContent.arrayBuffer();  // Convert the blob to an ArrayBuffer
        await writableStream.write(new Uint8Array(buffer));  // Write the content
        await writableStream.close();  // Close the writable stream

        // Cleanup: revoke the URL object after the write is completed
        window.URL.revokeObjectURL(url);
        return { showMessage: true };
    } catch (error) {
        console.error('Error downloading blob to directory:', error);
        return { error: true, showMessage: false };
    }
};


// Helper function to download the folder as a zip, with directory picker support
export const downloadFolderAsZip = async (containerName, folderPath, blobServiceClient) => {
    try {
        const containerClient = blobServiceClient.getContainerClient(containerName);

        // Use the File System Access API to let the user choose a directory
        const directoryHandle = await window.showDirectoryPicker();
        // Get the blobs from the folder
        const blobs = containerClient.listBlobsFlat({ prefix: folderPath });
        // Create a new JSZip instance
        const zip = new JSZip();
        for await (const blob of blobs) {
            const blobPath = blob.name; // Full path of the blob in Azure
            const blobClient = containerClient.getBlobClient(blobPath);
            const downloadResponse = await blobClient.download(0);
            const blobData = await downloadResponse.blobBody;
            const fileName = blobPath.replace(folderPath, ''); // Remove the folder prefix to simulate folder structure in the zip

            // Add each blob to the zip
            zip.file(fileName, blobData);
        }

        // Generate the zip file as a Blob
        const zipBlob = await zip.generateAsync({ type: "blob" });


        // Sanitize the file name to avoid invalid characters
        const sanitizeFileName = (fileName) => {
            return fileName.replace(/[<>:"/\\|?*]/g, '_');  // Replace any invalid characters with underscores
        };

        const zipFileName = sanitizeFileName(`${folderPath.replace('/', '_')}.zip`);

        // Create a file handle for the zip file within the selected directory
        const fileHandle = await directoryHandle.getFileHandle(zipFileName, { create: true });


        // Open a writable stream to the file and write the zip content
        const writableStream = await fileHandle.createWritable();
        const buffer = await zipBlob.arrayBuffer();  // Convert the Blob to an ArrayBuffer

        await writableStream.write(new Uint8Array(buffer));  // Write the content
        await writableStream.close();  // Close the writable stream
        return { showMessage: true };
    } catch (error) {
        console.error('Error downloading folder as zip:', error);
        return { error: true, showMessage: false };
    }
};






// Helper function to convert a readable stream to a string
export const getBlobContent = async (containerName, blobPath) => {
    try {
        const blobServiceClient = initializeBlobServiceClient();
        const containerClient = blobServiceClient.getContainerClient(containerName);

        const blobClient = containerClient.getBlobClient(blobPath);
        const exists = await blobClient.exists();

        if (!exists) {
            return null;
        }

        const downloadBlockBlobResponse = await blobClient.download(0);
        const blobData = await downloadBlockBlobResponse.blobBody;

        // Determine the file extension
        const fileExtension = blobPath.split('.').pop().toLowerCase();

        // Handle PDF files by creating a URL
        if (fileExtension === 'pdf') {
            const arrayBuffer = await blobData.arrayBuffer(); // Convert blob to ArrayBuffer
            const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob); // Create a blob URL for the PDF
            return blobUrl; // Return the blob URL for PDF
        }
        // Check if the blob is a zip file
        else if (fileExtension === 'zip') {
            const zipContent = await extractZipContent(blobData);
            return zipContent; // Return the extracted content of the zip file
        } else {
            const textContent = await blobToString(blobData);
            return textContent; // Return the text content of the blob
        }
    } catch (error) {
        return { error: true };
    }
};



// Helper function to convert a Blob to a string
const blobToString = async (blob) => {
    const text = await blob.text(); // Use the Blob's built-in text method
    return text;
};

// Helper function to extract content from a ZIP Blob
const extractZipContent = async (blob) => {
    const zip = new JSZip();
    const content = await zip.loadAsync(blob); // Load the zip file
    const files = await Promise.all(
        Object.keys(content.files).map(async (filename) => {
            const fileData = await content.files[filename].async('text'); // Get each file as text
            return { filename, fileData };
        })
    );
    return files; // Return an array of extracted files
};
// get the meta data for approval

export const getFolderBlobMetadata = async (containerName, folderPath) => {

    try {
        // Authenticate using Azure AD
        const credential = new InteractiveBrowserCredential({ clientId, tenantId });
        const blobServiceClient = new BlobServiceClient(
            `https://${accountName}.blob.core.windows.net`,
            credential
        );

        // Get a reference to the container
        const containerClient = blobServiceClient.getContainerClient(containerName);

        // List blobs in the specified container
        const blobs = containerClient.listBlobsFlat();

        let metadataArray;

        for await (const blob of blobs) {
            // Check if the blob name starts with the folder path
            if (blob.name === (folderPath + '/')) {
                const blockBlobClient = containerClient.getBlockBlobClient(blob.name);
                const response = await blockBlobClient.getProperties();
                metadataArray = { blobName: blob.name, metadata: response.metadata };
                continue;
            }
        }
        return metadataArray; // Return the array of metadata objects
    } catch (error) {
        return { error: true };
    }
};

export const updateBlobMetadata = async (containerName, blobName, newMetadata) => {
    try {
        // Authenticate using Azure AD
        const credential = new InteractiveBrowserCredential({ clientId, tenantId });
        const blobServiceClient = new BlobServiceClient(
            `https://${accountName}.blob.core.windows.net`,
            credential
        );

        // Get a reference to the container
        const containerClient = blobServiceClient.getContainerClient(containerName);

        // Get a reference to the blob
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);

        // Set the new metadata
        await blockBlobClient.setMetadata(newMetadata);
    } catch (error) {
        return { error: true };
    }
};


// Promote the build to the next stage 


export const copyBlobsBetweenFolders = async (containerName, sourceFolderPath, destinationFolderPath) => {

    try {
        // Authenticate using Azure AD
        const credential = new InteractiveBrowserCredential({ clientId, tenantId });
        const blobServiceClient = new BlobServiceClient(
            `https://${accountName}.blob.core.windows.net`,
            credential
        );

        // Get a reference to the container
        const containerClient = blobServiceClient.getContainerClient(containerName);

        // List blobs in the source folder
        const blobs = containerClient.listBlobsFlat({ prefix: sourceFolderPath });

        for await (const blob of blobs) {
            const sourceBlobName = blob.name;
            const destinationBlobName = destinationFolderPath + sourceBlobName.slice(sourceFolderPath.length);

            // Construct the source blob URL
            const sourceBlobUrl = `https://${accountName}.blob.core.windows.net/${containerName}/${sourceBlobName}`;

            // Start copying the blob
            const destinationBlobClient = containerClient.getBlockBlobClient(destinationBlobName);
            await destinationBlobClient.startCopyFromURL(sourceBlobUrl);
        }

        return `Successfully copied blobs from "${sourceFolderPath}" to "${destinationFolderPath}".`;
    } catch (error) {
        return { error: true };
    }
};

// delete all the blob from the some specific folder 


export const deleteBlobsFromFolder = async (containerName, folderPath) => {
    try {
        // Authenticate using Azure AD
        const credential = new InteractiveBrowserCredential({ clientId, tenantId });
        const blobServiceClient = new BlobServiceClient(
            `https://${accountName}.blob.core.windows.net`,
            credential
        );

        // Get a reference to the container
        const containerClient = blobServiceClient.getContainerClient(containerName);

        // List blobs in the folder (using the folderPath as the prefix)
        const blobs = containerClient.listBlobsFlat({ prefix: folderPath });

        // Initialize an array to hold deletion promises
        const deletePromises = [];

        for await (const blob of blobs) {
            const blobName = blob.name;

            // Make sure we are only deleting blobs directly inside the folder
            if (blobName.startsWith(folderPath)) {
                // Create a blob client for each blob and add delete operation to the array
                const blobClient = containerClient.getBlobClient(blobName);
                deletePromises.push(blobClient.delete());
            }
        }

        // Execute all delete operations
        await Promise.all(deletePromises);
    } catch (error) {
        return { error: true };
    }
};


export const createEmptyFolderStructure = async (containerName, sourceFolderPath, destinationFolderPath) => {
    try {
        // Authenticate using Azure AD
        const credential = new InteractiveBrowserCredential({ clientId, tenantId });
        const blobServiceClient = new BlobServiceClient(
            `https://${accountName}.blob.core.windows.net`,
            credential
        );

        // Get a reference to the container
        const containerClient = blobServiceClient.getContainerClient(containerName);

        // List blobs in the source folder
        const blobs = containerClient.listBlobsFlat({ prefix: sourceFolderPath });

        for await (const blob of blobs) {
            const sourceBlobName = blob.name;
            const destinationBlobName = destinationFolderPath + sourceBlobName.slice(sourceFolderPath.length);

            // Create an empty blob at the destination
            const destinationBlobClient = containerClient.getBlockBlobClient(destinationBlobName);
            await destinationBlobClient.upload("", 0); // Upload an empty blob
        }

        return `Successfully created empty folder structure from "${sourceFolderPath}" to "${destinationFolderPath}".`;
    } catch (error) {
        return { error: true, message: error.message };
    }
};
