import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';


window.view = "Thumbnail";

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIRNTID_URL,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANTID_URL}`,
    redirectUri: process.env.REACT_APP_REDIRECTING_URL, // Your app's redirect URI
  },
};

// Calculate the zoom factor for the zoom in zoom event on screen using mouse or keyboard
function updateZoom() {
  const zoomLevel = window.outerWidth / window.innerWidth;
  const zoomPercentage = Math.round(zoomLevel * 100);
  const zoomLevelRounded = Math.round(zoomLevel * 100) / 100;
  if (zoomPercentage <= 320) {
    document.documentElement.style.transform = `scale(${zoomLevelRounded})`;
    document.documentElement.style.transformOrigin = '0 0';
    document.documentElement.style.setProperty('--zoom', zoomLevelRounded);
  }
}

updateZoom();

window.addEventListener('resize', updateZoom);


const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
