
import axios from 'axios';
import {getArtifactFromBuild} from './DownloadContent';
import JSZip from 'jszip';
import {accessToken} from '../../../Hook/userRoles';
let fileExtension;
const isTextFile = (
  fileExtension === 'txt' ||
  fileExtension === 'md' ||
  fileExtension === 'log' ||
  fileExtension === 'js' ||
  fileExtension === 'css' ||
  fileExtension === 'html' ||
  fileExtension === 'xml' ||
  fileExtension === 'json' ||
  fileExtension === 'yaml' ||
  fileExtension === 'yml' ||
  fileExtension === 'csv' ||
  fileExtension === 'ini' ||
  fileExtension === 'cfg' ||
  fileExtension === 'config' ||
  fileExtension === 'rtf' ||
  fileExtension === 'htm' ||
  fileExtension === 'py' ||
  fileExtension === 'java' ||
  fileExtension === 'cpp' ||
  fileExtension === 'c' ||
  fileExtension === 'bat' ||
  fileExtension === 'sh' ||
  fileExtension === 'svg'
);

export async function getBuildsDetails( instance,accounts, runIds) {
  document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
  let queryParams = `api-version=6.1`;
  if (runIds) {
    queryParams = `${queryParams}&buildIds=${runIds}`;
  }
  const response = await fetch(
    `https://dev.azure.com/${process.env.REACT_APP_AUTH_CLIENT_ID}/${process.env.REACT_APP_PUBLIC_KEY}/_apis/build/builds?${queryParams}`,
    {
      headers: {
        Authorization: `Bearer ${await accessToken(instance, accounts)}`,
      },
    }
  );
  if(response.status !== 200){
    hideLoading();
    document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
    return {showMessage:true,message:`There was a problem getting the data. Please refresh the page and try again.`};
  }
  document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
  const result = await response.json();
  return result.value.filter(r => r.parameters).map(r => ({...r, parameters: JSON.parse(r.parameters)}));
}

async function checkSrcFolder(resultArray, folderPath,branchName,instance, accounts) {
  const buildIds = resultArray.map(result => result.buildId);
  const builds = await getBuildsDetails(instance, accounts, buildIds.join(','));
    if(builds.showMessage){
      return builds;
    }
  
  for (let build of builds) {
    if (build.parameters.srcFolder === folderPath) {
      const matchingResult = resultArray.find(result => result.buildId === build.id);
      if (matchingResult) {
          return  {showMessage:false,message:'Build Found',resultMatch:true,approvalObject:{approvalId:matchingResult.approvalId}};
      } else {
          document.getElementById('UploadButton')?.classList?.remove('d-none');
          document.getElementById('CreateFolderButton')?.classList?.remove('d-none');
          return {showMessage:false,message:'',resultMatch:false};
      }
    } else {
        document.getElementById('UploadButton')?.classList?.remove('d-none');
        document.getElementById('CreateFolderButton')?.classList?.remove('d-none');
    }
  }
  return {showMessage:false,message:'',resultMatch:false};
}

function showLoading() {
  var loadingContainer = document.getElementById('loadingContainer');
  if (loadingContainer && loadingContainer.style) {
    loadingContainer.style.display = 'flex';
  }
}

// Function to hide loading animation
function hideLoading() {
  var loadingContainer = document.getElementById('loadingContainer');
  if (loadingContainer && loadingContainer.style) {
    loadingContainer.style.display = 'none';
  }
}


const fetchAllPipelineRuns = async (folderPath, instance, accounts) => {
  const allRuns = [];
  let continuationToken = null;
  const apiVersion = '6.0-preview.1';
  const pipelineId = process.env.REACT_APP_ApprovalPipeline; // Adjust as needed

  do {
    const runsUrl = `https://dev.azure.com/${process.env.REACT_APP_AUTH_CLIENT_ID}/${process.env.REACT_APP_PUBLIC_KEY}/_apis/pipelines/${pipelineId}/runs?api-version=${apiVersion}${continuationToken ? `&continuationToken=${continuationToken}` : ''}`;

    try {
      const response = await fetch(runsUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${await accessToken(instance, accounts)}`
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch pipeline runs: ${response.statusText}`);
      }

      const data = await response.json();
      allRuns.push(...data.value);

      continuationToken = data.ContinuationToken;
    } catch (error) {
      console.error('Error fetching pipeline runs:', error);
    }
  } while (continuationToken);

  return allRuns;
};

// Call the function and process the data
export const getPipelineRunUsingVariable = async (folderPath, instance, accounts, userCanApprove) => {
  const allRuns = await fetchAllPipelineRuns(folderPath, instance, accounts);

  // Filter runs based on status, result, and srcFolder value
  const filteredRuns = allRuns.filter(run => 
    run.variables && 
    run.variables.srcFolder && 
    run.variables.srcFolder.value === folderPath
  );


  if (filteredRuns.length === 0) {
    return null; // No runs found
  }

  // Find the run with the latest finishedDate
  const latestRun = filteredRuns.reduce((latest, current) => {
    const latestDate = new Date(latest.finishedDate);
    const currentDate = new Date(current.finishedDate);
    return currentDate > latestDate ? current : latest;
  });


  if (latestRun.result === "failed" && latestRun.state === "completed") {
    document.getElementById('Rejected')?.classList?.remove('d-none');
    document.getElementById('Approved')?.classList?.add('d-none');
    document.getElementById('UploadButton')?.classList?.remove('d-none');
     document.getElementById('Inprogress')?.classList?.add('d-none');
  } else if (latestRun.state === "completed" && latestRun.result === 'succeeded') {
    document.getElementById('Rejected')?.classList?.add('d-none');
    document.getElementById('Approved')?.classList?.remove('d-none');
     document.getElementById('Inprogress')?.classList?.add('d-none');
  } else if(latestRun.state === "inProgress"){
    if(!userCanApprove && latestRun.state === "inProgress"){
      document.getElementById('Inprogress')?.classList?.remove('d-none');
      document.getElementById('UploadButton')?.classList?.add('d-none');
    }
    document.getElementById('Rejected')?.classList?.add('d-none');
    document.getElementById('Approved')?.classList?.add('d-none');
  }
  return latestRun; // Return the latest run
};





export const BuildDetails = async (folderPath,branchName,instance, accounts) => {


    // Function to fetch builds for the specified repository and branch
    const fetchBuilds = async () => {
        try {
          document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
            let manualInterventionsUrl = `https://dev.azure.com/${process.env.REACT_APP_AUTH_CLIENT_ID}/${process.env.REACT_APP_PUBLIC_KEY}/_apis/pipelines/approvals?state=pending&api-version=7.1-preview.1`;
              
            const response = await axios.get(manualInterventionsUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${await accessToken(instance, accounts)}`
                }
            });
            if (response.status === 200){
              const pipelines = response.data.value;
              const resultArray = pipelines.map(approval => ({
                  approvalId: approval.id,
                  buildId: approval.pipeline.owner.id
              }));
              // this pipeline id needed when we are going to copy and paste the data to the destination at that time 
              // we requred this pipeline id we will update the configration firt and then we will update the approval request
              window.pipelineId= process.env.REACT_APP_ValidationPipeline;
              
              document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
              return await checkSrcFolder(resultArray, folderPath,branchName,instance, accounts);
            } else {
              document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
              hideLoading();
              return {showMessage:true,message:'There was an issue fetching the approval details. Please refresh the page and try again.'};
            }
        } catch (error) {
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          hideLoading();
          return {showMessage:true,message:'There was an issue fetching the approval details. Please refresh the page and try again.'};
        }
    }; 
       return await fetchBuilds();
};




export const fetchDataRootLevel = async (folderPath,branchName,approvalId, approvalStatus,extractedPath,instance, accounts) => {
    try {
      const url = process.env.REACT_APP_BASE_URL + `items?scopePath=/${extractedPath}&recursionLevel=OneLevel&versionDescriptor.version=${branchName}&api-version=7.1-preview.1`;
      document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${await accessToken(instance, accounts)}`,
        }
      });
        if (response.status === 200){
          const dataReceived = response.data.value;
          const filteredDataReceived = dataReceived.map(({ path, isFolder }, index) => {
            if (path === '/') return null;
            if (path.includes("/") && index === 0) {
              return null;
            }
            if (path.includes(".gitignore")){
              return null;
            }
            return {
              path: path.replace(/^\/|\/$/g, ''),
              isFolder: isFolder !== undefined ? isFolder : false,
              isOpen: false
            };
          }).filter(obj => obj !== null);
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          hideLoading();
          return {showMessage:false,message:'',filteredDataReceived:filteredDataReceived};
        } else {
          hideLoading();
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          return {showMessage:true,message:'There was an issue fetching the approval details. Please refresh the page and try again.'};
        }
    } catch (error) {
      hideLoading();
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return {showMessage:true,message:'There was an issue fetching the approval details. Please refresh the page and try again.'};
    }
  };


export const sendCustomEmail = async ( approvalStatus,branchName,srcFolderArchive,instance, accounts,destinationFolderRecived=[],destFolder,folderPath) => {
        window.pipelineId = process.env.REACT_APP_EmailPipeline;
        let destinationFolder;
        let path = srcFolderArchive.split('/Submission');
        destinationFolder = path[0] + "/Submission/Archive" + path[1];
        const archiveName = (branchName && folderPath) ? branchName + "_" + folderPath.replaceAll('/',"_"):'';
        const artifactNameForVersion = archiveName?archiveName.toLowerCase().replaceAll("_", "-"):'';
        document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');

    const url = `https://dev.azure.com/${process.env.REACT_APP_AUTH_CLIENT_ID}/${process.env.REACT_APP_PUBLIC_KEY}/_apis/pipelines/${window.pipelineId}/runs?api-version=7.1-preview.1`;
    const data = {
      resources: {
        repositories: {
          self: {
            refName: `refs/heads/${branchName}`
          }
        }
      },
      variables: {
        approvalStatus: {
          value: `${approvalStatus}`
        },
        srcFolder: {
          value: `${srcFolderArchive+"/"}`
        },
        destinationFolder: {
          value: `${destinationFolder}`
        },
        artifactName : {
          value: `${archiveName}`
        },
        artifactVersion: {
          value : `${artifactNameForVersion}`
        }
      }
    };
  
    try {
        
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${await accessToken(instance, accounts)}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status !== 200) {
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        hideLoading();
        return  {showMessage:true,message:`There was an issue fetching the approval details. Please refresh the page and try again.`};
      } else {
        document.getElementById('Rejectbutton')?.classList?.add('d-none');
        document.getElementById('Approvebutton')?.classList?.add('d-none');
        document.getElementById('UploadButton')?.classList?.remove('d-none');
        document.getElementById('CreateFolderButton')?.classList?.remove('d-none');
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        if(destinationFolderRecived  && approvalStatus === 'success'){
          if(destinationFolderRecived.split('/').pop() !== 'BusinessRelease'){
            const response = await updatePipelineConfiguration(destFolder, null, null,  null, branchName,process.env.REACT_APP_ApprovalPipeline,'',instance, accounts);
            if(response.showMessage){
              return response;
            }
          }
        }
        hideLoading();
      }
  
      return response.data;
    } catch (error) {
        hideLoading();
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        return {showMessage: true,message:`There was an issue fetching the approval details. Please refresh the page and try again.`};
    }
  };


  export const updatePipelineConfiguration = async (folderPath, destinationFolder, approvalId = null, approvalStatus = null, branchName,pipelineID=null,srcFolder,instance, accounts,buildIDRender) => { 
   showLoading();
    if(pipelineID){
      window.pipelineId = pipelineID;
    } else {
      if ((folderPath.includes('Prerelease'))) {
            window.pipelineId = process.env.REACT_APP_ReleasePipeline;
        } else if((destinationFolder.includes('Prerelease'))) {
          window.pipelineId = process.env.REACT_APP_PreReleasePipeline; 
        } else {
          window.pipelineId = process.env.REACT_APP_ValidationPipeline; 
        }
    }
    let releaseStage;
    if(destinationFolder){
      if ((destinationFolder.includes('Prerelease'))){
        releaseStage = 'pre-release';
      } else if (destinationFolder.includes('Validation')) {
        releaseStage = 'validation-release';
      } else if (destinationFolder.includes('BusinessRelease')){
        releaseStage = 'business-release';
      }
    }

    const destFolder = destinationFolder +"/" +folderPath.split('/').pop();
    let  artifactName ;
     if(destinationFolder){
        const artifatoryName = destinationFolder.split('/');
        artifactName = branchName + "_" + artifatoryName[0] + "_" + folderPath.split('/').pop();
      } else if(folderPath){
         const artiFactoryNameSubmited =  folderPath.split('/');
         artifactName = branchName + "_" + artiFactoryNameSubmited[0] + "_" +  folderPath.split('/').pop();
        }
        const artifactData = artifactName;
        const artifactNameForVersion = artifactData.toLowerCase().replaceAll("_", "-");
        document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
      
    const url = `https://dev.azure.com/${process.env.REACT_APP_AUTH_CLIENT_ID}/${process.env.REACT_APP_PUBLIC_KEY}/_apis/pipelines/${window.pipelineId}/runs?api-version=7.1-preview.1`;
    const data = {
      resources: {
        repositories: {
          self: {
            refName: `refs/heads/${branchName}`
          }
        }
      },
      variables: {
        destinationFolder: {
          value: `${destFolder}`
        },
        srcFolder: {
          value: srcFolder?srcFolder:folderPath
        },
        artifactName : {
          value: artifactName
        },
        releaseStage : {
          value: releaseStage
        },
        artifactVersion: {
          value : `${artifactNameForVersion}`
        },
        azureBlobContainer:{
          value: branchName.toLowerCase()
        },
        azureBlobFolder:{
          value: srcFolder?srcFolder:folderPath
        },
        buildIDRender:{
          value: buildIDRender
        }
      }
    };
  
    try {
        
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${await accessToken(instance, accounts)}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status !== 200) {
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        hideLoading();
        return  {showMessage:true,message:`Error in updated software information. Kindly revisit the Process .`};
      } else {
        hideLoading();
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      }
  
      return response.data;
    } catch (error) {
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        hideLoading();
        return  {showMessage:true,message:`There was an issue fetching the approval details. Please refresh the page and try again.`};
      }
  };
  
  


export const finalApprovalRequest = async (approvalId, approvalStatus,branchName,folderPath,instance, accounts,destinationFolder,destFolder) => {
  document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
    try {
        let manualInterventionsUrl =  `https://dev.azure.com/${process.env.REACT_APP_AUTH_CLIENT_ID}/${process.env.REACT_APP_PUBLIC_KEY}/_apis/pipelines/approvals?api-version=7.1-preview.1`;
        const responseApproval = await axios.patch(manualInterventionsUrl, 
            [
                {
                    "approvalId": approvalId,
                    "comment": "Approving",
                    "status": approvalStatus // "approved" or "rejected"
                }
            ],
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${await accessToken(instance, accounts)}`
                }
            }
        );

        if (responseApproval.status === 200) {
            document.getElementById('Rejectbutton')?.classList?.add('d-none');
            document.getElementById('Approvebutton')?.classList?.add('d-none');
            document.getElementById('UploadButton')?.classList?.remove('d-none');
            document.getElementById('CreateFolderButton')?.classList?.remove('d-none');
            if(approvalStatus === 'approved') {
                const response = await sendCustomEmail('success',branchName,window.srcFolder,instance, accounts,destinationFolder,destFolder,folderPath);
                if(response.showMessage){
                  return response;
                }
              } else {
                const response = await sendCustomEmail('failure',branchName,window.srcFolder,instance, accounts,"","",folderPath);
                if(response.showMessage){
                  return response;
                }
            }
            document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
            return  {showMessage:true,message:`Software ${approvalStatus} at ${folderPath.replaceAll("/",">")}.`};
        } else {
            hideLoading();
            document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
            return  {showMessage:true,message:`Failed to ${approvalStatus} Software`};
        }
    } catch (error) {
        hideLoading();
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        return  {showMessage:true,message:`There was an issue fetching the approval details. Please refresh the page and try again.`};
    }
}





export const approvalForPrerelease = async (dataForValidationFolder,BuildId,instance, accounts) => {
            const downloadURL =  await getArtifactFromBuild(BuildId?BuildId:dataForValidationFolder[0].id,instance, accounts);
            if (downloadURL.showMessage === true){
              return downloadURL;
             }
            return  downloadUrlDataAndFetchFilelist(downloadURL,instance, accounts);
}

// show the files Present in the build is self
export const downloadUrlDataAndFetchFilelist = async (downloadUrl,instance, accounts) => {
  document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
    const response = await fetch(downloadUrl, {
      headers: {
        Authorization: `Bearer ${await accessToken(instance, accounts)}`,
      }
    });
  
    if (!response.ok) {
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return {showMessage: true,message:`There's a problem getting the data. Please refresh the page and try again.`};
    }
  
    const blob = await response.blob();
    const arrayBuffer = await blob.arrayBuffer();
    const zip = await JSZip.loadAsync(arrayBuffer);
    const filePaths = [];
    
    try {
      // Get the list of file names in the ZIP archive
      const fileNames = Object.keys(zip.files);
    
      // Iterate over each file in the ZIP archive
      await Promise.all(fileNames.map(async (filename) => {
        try {
          // Read the content of the file as text
          const fileToFile = filename;
           fileExtension = fileToFile.split(".").pop();
          if(fileExtension !== 'pdf'){
            if(isTextFile){
              const fileContent = await zip.files[filename].async('text');
              if(fileContent){
                filePaths.push({ relativePath: filename, fileContent: isTextFile?fileContent:''});
              }
            } else {
                filePaths.push({ relativePath: filename, fileContent:''});
            }
          } else if(fileExtension === 'pdf') {
                const arrayBuffer = await zip.files[filename].async('arraybuffer');
                const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                if(url){
                  filePaths.push({ relativePath: filename, fileContent: url });
                }
          }

          
        } catch (error) {
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          return {showMessage: true,message:`There's a problem getting the data. Please refresh the page and try again.`};
        }
      }));
    } catch (error) {
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return {showMessage: true,message:`There's a problem getting the data. Please refresh the page and try again.`};
    }
    document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
    return filePaths; // Array of objects containing relativePath and fileContent
  };
  

  export const downloadUrlDataAndFetchFile = async (downloadUrl, instance, accounts, targetFileName) => {
    document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');
  
    const response = await fetch(downloadUrl, {
      headers: {
        Authorization: `Bearer ${await accessToken(instance, accounts)}`,
      }
    });
  
    if (!response.ok) {
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return { showMessage: true, message: `There's a problem getting the data. Please refresh the page and try again.` };
    }
  
    const blob = await response.blob();
    const arrayBuffer = await blob.arrayBuffer();
    const zip = await JSZip.loadAsync(arrayBuffer);
  
    try {
      const fileNames = Object.keys(zip.files);
      

  
      // Try to find the target file
      const fileToReturn = fileNames.find(name => {
        return name.split('/').pop() === targetFileName;  // Ensure to return the result of the comparison
      });
  
  
      if (fileToReturn) {
        const fileExtension = fileToReturn.split('.').pop();
  
        if (fileExtension === 'pdf') {
          const arrayBuffer = await zip.files[fileToReturn].async('arraybuffer');
          const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
          const url = URL.createObjectURL(blob);
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          return url; // Return URL for PDF
        } else {
          const fileContent = await zip.files[fileToReturn].async('text');
          document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
          return fileContent; // Return content for text files
        }
      } else {
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        return { showMessage: true, message: `File not found.` };
      }
    } catch (error) {
      console.error('Error fetching or processing zip:', error);
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return { showMessage: true, message: `There's a problem getting the data. Please refresh the page and try again.` };
    }
  };
  
  
  