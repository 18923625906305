import React from 'react';

function DynamicButton({ text, onClickHandler, color, height, width ,className}) {
  const buttonStyle = {
    backgroundColor: color || 'black',
    color: 'white',
    padding: '.83vw 1.66vw',
    borderRadius: '0.41vw',
    cursor: 'pointer',
    height: height || 'auto', // Use the provided height or auto
    width: width || '5vw',   // Use the provided width or auto
    fontSize:'2vh'
  };

  return (
    <button className={'css-Button-Classes-infoPuop'}  onClick={onClickHandler}>
      {text}
    </button>
  );
}

export default DynamicButton;
