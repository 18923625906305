import React, { useState } from 'react';
import DynamicButton from "../ButtonComponent/DynamicButton.jsx";
import "./ErrorPopup.css";

const ErrorPopup = ({ message, onClose }) => {
  const [isOpen, setIsOpen] = useState(true);

  const closePopup = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    isOpen && (
      <div className='fullScreenBlur'>
          <div className="Modal-Popup-css">
            <div className="Popupcss">
              <span className="closeButton" onClick={closePopup}>&times;</span>
              <p>{message}</p>
              <DynamicButton
                color={"green"}
                text="OK"
                onClickHandler={closePopup}
              />
            </div>
          </div>
      </div>
    )
  );
};

export default ErrorPopup;
