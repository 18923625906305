import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import CreateFolderForm from '../AccessRepo/CreateFolderForm';
import ErrorPopup from '../ModelPopup/ErrorPopup'
import {updatePipelineConfiguration} from '../AccessRepo/BuildDetails'
import ModalPopup from './ModalPopup';
import {createFolderByPathInContainer,getBlobMetadata,updateBlobMetadata} from '../AccessRepo/AzureBlobUploader';
const ApproveBuildPopup = ({ filteredDataReceived, folderPath, approvalId, approvalStatus, branchName, instance, accounts , closePopupShown}) => {
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // Initially disable the OK button
  const [path, setPath] = useState();
  const [renderCreateNewfolder,setrenderCreateNewfolder] = useState(false);
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageInfo, setMessageInfo] = useState('');
  const [ismodalPopup,setismodalPopup] = useState(false);
  let errorResult ; 

  const handelCompoenentErrorBundries = (result) => {
       if(result){
            if(result.error){
              setMessageInfo(`There was a error in operation. Please refresh the page and try again.`);
              setismodalPopup(true); // Toggle the state
            }
       }
  }
  const closePopup = () => {
    closePopupShown();
  };

  const handleOkButtonClick = async () => {
    setMessageInfo(`Please confirm you want to approve selected build at ${formatPathLabel(folderPath)} to ${formatPathLabel(selectedFolder)}`);
    setismodalPopup(true); // Toggle the state
  }

  const updateapproval = async () => {
    let folderName = folderPath.split('/').slice(-1)[0]; 
    const destinatinoFolder = `${selectedFolder}/${folderName}/`;
    errorResult =  await createFolderByPathInContainer(branchName.toLowerCase(), destinatinoFolder);
    handelCompoenentErrorBundries(errorResult);
      // await copyBlobsBetweenFolders(branchName.toLowerCase(), folderPath+'/', destinatinoFolder);
    //  await createEmptyFolderStructure(branchName.toLowerCase(), folderPath+'/', destinatinoFolder);
        const metaData = {
          status: 'inprogress',
          userName: 'value2',
          comment: ''
      };
      errorResult = await updateBlobMetadata(branchName.toLowerCase(), destinatinoFolder,metaData);
      const metaDataResult = await getBlobMetadata(branchName.toLowerCase(), folderPath + '/');
      window.buildIDRender = metaDataResult.buildid ? metaDataResult.buildid : window.buildIDRender;
      handelCompoenentErrorBundries(errorResult);
        const newMetadata = {
          status: 'inprogress',
          userName: 'value2',
          comment:'',
          buildid:window.buildIDRender,
          stage:destinatinoFolder
      };
      errorResult = await updateBlobMetadata(branchName.toLowerCase(), folderPath+'/',newMetadata);
      handelCompoenentErrorBundries(errorResult);
      setismodalPopup(false); // Toggle the state
      setIsErrorPopupOpen(true); // Toggle the state
      setMessage("Software approved at "+branchName+'/'+folderPath);
     
        const responceData = await updatePipelineConfiguration(folderPath,selectedFolder,approvalId, approvalStatus,branchName,null,null,instance, accounts,window.buildIDRender);
        if (responceData.showMessage === true){
            setismodalPopup(false); // Toggle the state
            setIsErrorPopupOpen(true); // Toggle the state
            setMessage(responceData.message);
        } 
    return;

  }
  const handeldata = () => {
    setrenderCreateNewfolder(false);
    closePopupShown();
  };

  const handleFolderSelection = (path) => {
    // Toggle selection logic
    if (selectedFolder === path) {
      setSelectedFolder(null); // Deselect if already selected
      setIsButtonDisabled(true); // Disable OK button
    } else {
      setSelectedFolder(path); // Select the folder
      setIsButtonDisabled(false); // Enable OK button
    }
  };

  const handleCreateFolder = () => {
    setSelectedFolder(path);
    setrenderCreateNewfolder(true);
  };

  function formatPathLabel(fileName) {
    return fileName.replace(/(Prerelease|ValidationRelease(\d*\.\d*)|BusinessReleaseDocs|BusinessRelease)/g, (match) => {
      if (match.startsWith('Prerelease')) return 'Pre Release';
      if (match.startsWith('ValidationRelease')) return `Validation Release ${match.replace('ValidationRelease', '')}`;
      if (match.startsWith('BusinessReleaseDocs')) return 'Business Release Docs';
      if (match.startsWith('BusinessRelease')) return 'Business Release';
      return match;
    });
  }

 const listRender = (filteredDataReceived) => {
   return filteredDataReceived.map((data) => {
        if((data.isFolder && (folderPath.includes('Submission')) && data.path.includes('Validation'))){
            return <div key={data.path} className="checkboxItem">
                <Radio
                    checked={selectedFolder === data.path}
                    onChange={() => handleFolderSelection(data.path)}
                />
                <span>{formatPathLabel(data.path.split('/').pop())}</span>
                </div>
        } else if(data.isFolder && (folderPath.includes('Validation')) && data.path.includes('Prerelease') && !data.path.includes('BusinessRelease')){
            return <div key={data.path} className="checkboxItem">
            <Radio
                checked={selectedFolder === data.path}
                onChange={() => handleFolderSelection(data.path)}
            />
            <span>{formatPathLabel(data.path.split('/').pop())}</span>
            </div>
        } else if((data.isFolder && (folderPath.includes('Prerelease')) && data.path.includes('BusinessRelease') && !data.path.includes('Validation') && !data.path.includes('Prerelease') && !data.path.includes('BusinessReleaseDocs'))){
            return <div key={data.path} className="checkboxItem">
            <Radio
                checked={selectedFolder === data.path}
                onChange={() => handleFolderSelection(data.path)}
            />
            <span>{formatPathLabel(data.path.split('/').pop())}</span>
            </div>
        }
     })
 } 

  return (
    <div className='fullScreenBlur'>
      <div className="setBackgroundModalPopup">
        <div className="Modal-Popup-css">
          <span className="closeButton" onClick={closePopup}>&times;</span>
          <div className="contentAreaApproval">
            <div>
              {folderPath.includes('Submission') ? 'Do you want to validate the software? You can select an existing directory or create a new one' : 'Please select an existing Directory to validate the software'}
            </div>
            <ul className="uLListForFolderSelection">
              {listRender(filteredDataReceived)}
            </ul>
          </div>
          <div className='ButtonConatinerOncheckList'>
            <button className='css-Button-Classes-infoPuop' onClick={handleOkButtonClick} disabled={isButtonDisabled}>OK</button>
            {folderPath.includes('Submission') && instance && accounts && (
              <button className='css-Button-Classes-infoPuop' onClick={handleCreateFolder}>
                Create Folder
              </button>
            )}
          </div>
        </div>
      </div>
      {renderCreateNewfolder && 
        <CreateFolderForm 
          branchName={branchName}
          onCloseButton={() => handeldata()}
          newBranch={'Folder'}
          folderLocation={folderPath}
          approvalId={approvalId}
          approvalStatus={approvalStatus}
          msalInstance={instance}
          msalAccounts={accounts}
        />
      }
      {isErrorPopupOpen && (
        <ErrorPopup message={formatPathLabel(message)} onClose={closePopup} />
      )}
        {ismodalPopup && (
          <ModalPopup children={formatPathLabel(messageInfo)} onClose={closePopup}  onOperationCancel={updateapproval}/>
        )}
    </div>
  );
};

export default ApproveBuildPopup;
